import React from "react";
import Seo from "../../../components/seo";
import Layout from "../../../components/enterpriseConf2021/layout";
import CodeOfConductWrapper from "../../../components/enterpriseConf2021/homepage/codeofconductwrapper";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/enterprise-conf-2021.png",
};
const canonicalUrl = "https://hasura.io/enterprisegraphql/2021/code-of-conduct/";
const CodeOfConduct = props => (
  <Layout location={props.location}>
    <Seo
      title="Code of Conduct | Enterprise GraphQL Conf"
      description="All attendees, speakers, sponsors and volunteers at Enterprise GraphQL Conf 2021 are required to agree with the following code of conduct."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <CodeOfConductWrapper />
  </Layout>
);

export default CodeOfConduct;
