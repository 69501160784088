import React, { useEffect, useRef } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import {
  StyledDarkBlueBgColor,
  StyledContainerWrapper,
  StyledHeader,
  StyledNavList,
} from "./styles/StyledEGConf";
import { scrollToTop } from "../common/Header/helper";

import egcLogo from "./images/egc-logo-2021.svg";
import hasuraLogoWhite from "../../images/hasura-logo-white.svg";
const StyledDarkBlueBgColorSticky = styled(StyledDarkBlueBgColor)`
  position: sticky;
  top: 0;
  z-index: 1000;
`;

// const StyledGreenButtonSm = styled(StyledGreenButton)`
//   height: 40px;
//   padding: 4px 24px;
// `;

function openEGCMenu() {
  var x = document.getElementById("navbar");
  var hamberger = document.getElementById("menuClick");
  if (x.className === "egcTopNav") {
    x.className += " responsive";
    document.body.style.overflow = "hidden";
    document.getElementById("viewport").style.overflow = "hidden";
    hamberger.className += " open";
  } else {
    x.className = "egcTopNav";
    hamberger.className = "navBarToggle";
    document.body.style.overflow = null;
    document.getElementById("viewport").style.overflow = null;
  }
}

const Header = () => {
  const wrapperRef = useRef(null);
  useEffect(() => {
    // if (typeof window !== undefined) {
    //   window.onscroll = function () {
    //     var currentScrollPos = window.pageYOffset;
    //     var tagTop = document.getElementById("header-egc");
    //     var navBtnTop = document.getElementById("navRegBtn");
    //     if (currentScrollPos > 164 && tagTop !== null) {
    //       navBtnTop.className = "buttonWrapper navMenuBtnShow";
    //     } else {
    //       navBtnTop.className = "buttonWrapper navMenuBtnHide";
    //     }
    //   };
    // }
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);
  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      var x = document.getElementById("navbar");
      var hamberger = document.getElementById("menuClick");
      if (x.className === "egcTopNav responsive") {
        x.className = "egcTopNav";
        hamberger.className = "navBarToggle";
        document.body.style.overflow = null;
        document.getElementById("viewport").style.overflow = null;
      }
    }
  };
  return (
    <StyledDarkBlueBgColorSticky id="header-egc">
      <StyledContainerWrapper>
        <StyledHeader>
          <div className="brand">
            <a href="/" onClick={scrollToTop}>
              <img src={hasuraLogoWhite} alt="Hasura Logo" title="Hasura Logo" />
            </a>
          </div>
          <div className="egcBrand">
            <Link to="/enterprisegraphql/2021/">
              <img src={egcLogo} alt="Brand" />
            </Link>
          </div>
          <div className="navWrapper hideNavMobile">
            <ul>
              <StyledNavList>
                <a href="/enterprisegraphql/2021/#talks">Talks</a>
              </StyledNavList>
              <StyledNavList>
                <a href="/enterprisegraphql/2021/#about">About</a>
              </StyledNavList>
            </ul>
          </div>
          <div id="navbar" className="egcTopNav">
            <div className="navBarToggleBg">
              {/*eslint-disable-next-line*/}
              <span
                className="navBarToggle"
                aria-label="button"
                role="button"
                tabIndex="0"
                onClick={() => openEGCMenu()}
                id="menuClick"
              >
                <span className={"iconBar"}></span>
                <span className={"iconBar"}></span>
                <span className={"iconBar"}></span>
              </span>
            </div>
            <div className="egcVisibleMobile">
              <ul>
                <StyledNavList>
                  <a href="/enterprisegraphql/2021/#talks" onClick={() => openEGCMenu()}>
                    Talks
                  </a>
                </StyledNavList>
                <StyledNavList>
                  <a href="/enterprisegraphql/2021/#about" onClick={() => openEGCMenu()}>
                    ABOUT
                  </a>
                </StyledNavList>
              </ul>
            </div>
          </div>
        </StyledHeader>
      </StyledContainerWrapper>
    </StyledDarkBlueBgColorSticky>
  );
};

export default Header;
