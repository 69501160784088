import React from "react";
import {Link} from 'gatsby';

import { StyledLightBlueBgColor, StyledContainerWrapper, StyledFooter } from "./styles/StyledEGConf";
import hasuraLogoColor from "../../images/hasura-logo-color.svg";
import githubBrands from "../../images/github-brands-gray.svg";
import twitterBrands from "../../images/twitter-brands-gray.svg";
import discordBrands from "../../images/discord-brands-gray.svg";
import facebookBrands from "../../images/facebook-brands-gray.svg";
import instagramBrands from "../../images/instagram-brands-gray.svg";
import youtubeBrands from "../../images/youtube-brands-gray.svg";
import linkedinBrands from "../../images/linkedin-brands-gray.svg";
const Footer = () => {
  return (
    <StyledLightBlueBgColor>
      <StyledContainerWrapper>
        <StyledFooter>
          <div className="brand">
            <a href="/">
            <img src={hasuraLogoColor} alt="Brand logo" />
            </a>
          </div>
          <div className="copyRight">
            © {new Date().getFullYear()} Hasura Inc. All rights reserved
          </div>
          <div className="footerSocialIconsWrapper">
            <div className="socialBrands">
              <a
                href={"https://github.com/hasura/graphql-engine"}
                rel="noopener noreferrer"
                aria-label={"Github"}
              >
                <img loading="lazy" src={githubBrands} alt="Github" />
              </a>
            </div>
            <div className="socialBrands">
              <a
                href={"https://twitter.com/hasurahq"}
                rel="noopener noreferrer"
                aria-label={"Titter"}
              >
                <img loading="lazy" src={twitterBrands} alt="Titter" />
              </a>
            </div>
            <div className="socialBrands">
              <a
                href={"https://discord.com/invite/hasura"}
                rel="noopener noreferrer"
                aria-label={"Discord"}
              >
                <img loading="lazy" src={discordBrands} alt="Discord" />
              </a>
            </div>
            <div className="socialBrands">
              <a
                href={"https://www.facebook.com/HasuraHQ"}
                rel="noopener noreferrer"
                aria-label={"Facebook"}
              >
                <img loading="lazy" src={facebookBrands} alt="Facebook" />
              </a>
            </div>
            <div className="socialBrands">
              <a
                href={"https://www.instagram.com/hasurahq/?hl=en"}
                rel="noopener noreferrer"
                aria-label={"Instagram"}
              >
                <img loading="lazy" src={instagramBrands} alt="Instagram" />
              </a>
            </div>
            <div className="socialBrands">
              <a
                href={"https://www.youtube.com/channel/UCZo1ciR8pZvdD3Wxp9aSNhQ"}
                rel="noopener noreferrer"
                aria-label={"Youtube"}
              >
                <img loading="lazy" src={youtubeBrands} alt="Youtube" />
              </a>
            </div>
            <div className="socialBrands">
              <a
                href={"https://www.linkedin.com/company/hasura"}
                rel="noopener noreferrer"
                aria-label={"Linkedin"}
              >
                <img loading="lazy" src={linkedinBrands} alt="Linkedin" />
              </a>
            </div>
          </div>
        </StyledFooter>
      </StyledContainerWrapper>
    </StyledLightBlueBgColor>
  );
};

export default Footer;
